import { createSlice } from '@reduxjs/toolkit';

const initialState= {
}


export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    getScheduleSuccess: (state, action) => {
      return action.payload;
    },
  },
});

export const { getScheduleSuccess } = scheduleSlice.actions;

export const fetchSchedule = () => dispatch => {
  dispatch(getScheduleSuccess({}));
};

export default scheduleSlice.reducer;
