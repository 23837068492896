import { createSlice } from '@reduxjs/toolkit';

const initialState= [];


export const liveScoreSlice = createSlice({
  name: 'liveScore',
  initialState,
  reducers: {
    getLiveScoreSuccess: (state, action) => {
      return action.payload;
    },
  },
});

export const { getLiveScoreSuccess, setCurrentGroup } = liveScoreSlice.actions;

export const fetchLiveScore = (competitionId) => dispatch => {
  const endpoint = window._env_.REACT_APP_OPTA_ENDPOINT + `/competitions/${competitionId}/livescore`;
  fetch(endpoint, {
    method: 'GET',
  })
  .then(res => res.json())
  .then(res => {
    let payload = res.filter(matchToFilter => matchToFilter.matchStatus !== "finished" && matchToFilter.matchStatus !== null).map(match => ({
      homeInfo: match.homeInfo,
      awayInfo: match.awayInfo,
      matchStatus: match.matchStatus,
      matchCurrentMinute: match.matchCurrentMinute,
      matchCityArena: match.matchCityArena,
      matchDate: match.matchTime,
      matchPenaltiesShootOut: match.matchPenaltiesShootOut,
    }));
    if (payload.length === 0) {
      payload = res.map(match => ({
        homeInfo: match.homeInfo,
        awayInfo: match.awayInfo,
        matchStatus: match.matchStatus,
        matchCurrentMinute: match.matchCurrentMinute,
        matchCityArena: match.matchCityArena,
        matchDate: match.matchTime,
        matchPenaltiesShootOut: match.matchPenaltiesShootOut,
      }));
    }
    dispatch(getLiveScoreSuccess(payload));
  })
  .catch((e) => {
    console.error(e)
  });
};

export default liveScoreSlice.reducer;
