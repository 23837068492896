import React from 'react';
import { useParams } from "react-router-dom";
import { SCREEN_TYPE_CINEMA, SCREEN_TYPE_KATHEDRAAL, SCREEN_TYPE_ROKIN_ROLLS, SCREEN_TYPE_THREESIXTY } from "../utils/getScreenType";

const Test = () => {
  const { screen, component, team } = useParams();
  const style = {
    left: '0%',
    top: '0%',
    position: 'absolute',
  };
  if (screen === SCREEN_TYPE_CINEMA) {
    style.width = '70%';
    style.height = '71.1%';
  } else if (screen === SCREEN_TYPE_KATHEDRAAL) {
    style.width = '33.4%';
    style.height = '100%';
  } else if (screen === SCREEN_TYPE_ROKIN_ROLLS) {
    style.width = '60%';
    style.height = '59.26%';
  } else if (screen === SCREEN_TYPE_THREESIXTY) {
    style.width = '93.33%';
    style.height = '88.89%';
  } else {
    style.width = '100%';
    style.height = '100%';
  }
  let url
  if (component === 'next_match') {
    url = `/worldcup/${component}/${team}/${screen}`
  } else {
    url = `/worldcup/${component}/${screen}`
  }
  return (
      <div style={style}>
        <iframe
            id="473532cb-edac-4c2a-89b4-513b884a5d46"
            title="473532cb-edac-4c2a-89b4-513b884a5d46"
            scrolling="no"
            src={url}
            // src="http://localhost:3000/worldcup/next_match/netherlands/cs_cinema"
            // src="http://localhost:3000/worldcup/live_score/cs_cinema"
            // src="http://localhost:3000/worldcup/standing/cs_cinema"
            // src="http://localhost:3000/worldcup/next_match/netherlands/cs_kathedraal"
            // src="http://localhost:3000/worldcup/live_score/cs_kathedraal"
            // src="http://localhost:3000/worldcup/standing/cs_kathedraal"
            allow="autoplay"
            style={{width: '100%', height: '100%', border: 'none'}}>

        </iframe>
      </div>
  );
};

export default Test;
