import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Test from "./test";

const Standing = lazy(() => import("../features/standing"));
const NextMatch = lazy(() => import("../features/NextMatch/NextMatch"));
const NextMatchTriple = lazy(() => import("../features/NextMatchTriple/NextMatchTriple"));
const NextMatchTwins = lazy(() => import("../features/NextMatchTwins/NextMatchTwins"));
const LiveScore = lazy(() => import("../features/LiveScore/LiveScore"));
const LiveScoreTriple = lazy(() => import("../features/LiveScoreTriple/LiveScoreTriple"));
const LiveScoreTwins = lazy(() => import("../features/LiveScoreTwins/LiveScoreTwins"));
const Schedule = lazy(() => import("../features/schedule"));
const KnockoutStage = lazy(() => import("../features/KnockoutStage"));
const KnockoutStageTwins = lazy(() => import("../features/KnockoutStageTwins"));
const KnockoutStageTriple = lazy(() => import("../features/KnockoutStageTriple"));
const WorlCupStanding = lazy(() => import("../features/worldcup/Standing"));
const WorlCupNextMatch = lazy(() => import("../features/worldcup/NextMatch"));
const WorlCupLiveScore = lazy(() => import("../features/worldcup/LiveScore"));

const Routes =  ({ childProps }) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path="/standing/:country" component={Standing} />
        <Route path="/standing" component={Standing} />
        <Route path="/next_match/:country" component={NextMatch} />
        <Route path="/next_match" component={NextMatch} />
        <Route path="/next_match_twins/NL" component={NextMatchTwins} />
        <Route path="/next_match_triple/NL" component={NextMatchTriple} />
        <Route path="/live_score/:country" component={LiveScore} />
        <Route path="/live_score" component={LiveScore} />
        <Route path="/live_score_triple/NL" component={LiveScoreTriple} />
        <Route path="/live_score_twins/NL" component={LiveScoreTwins} />
        <Route path="/schedule/:country" component={Schedule} />
        <Route path="/schedule" component={Schedule} />
        <Route path="/knockout_stage/:country" component={KnockoutStage} />
        <Route path="/knockout_stage_triple/NL" component={KnockoutStageTriple} />
        <Route path="/knockout_stage_twins/NL" component={KnockoutStageTwins} />
        <Route path="/worldcup">
          <Switch>
            <Route path="/worldcup/standing/:screen" component={() => <WorlCupStanding competitionUId="c4" />} />
            <Route path="/worldcup/next_match/:team/:screen" component={() => <WorlCupNextMatch competitionUId="c4" />} />
            <Route path="/worldcup/live_score/:screen" component={() => <WorlCupLiveScore competitionUId="c4" />} />
          </Switch>
        </Route>
        <Route path="/test/:screen/:component/:team" component={() => <Test/>} />
        <Route path="/test/" component={() => <Test/>} />
        <Route path="*">Not Found</Route>
      </Switch>
    </Suspense>
  );
};
export default Routes;
