import { createSlice } from '@reduxjs/toolkit';

const initialState= [];


export const standingSlice = createSlice({
  name: 'standing',
  initialState,
  reducers: {
    getStandingSuccess: (state, action) => {
      return action.payload;
    },
  },
});

export const { getStandingSuccess } = standingSlice.actions;

export const fetchStanding = (competitionId) => dispatch => {
  const endpoint = window._env_.REACT_APP_OPTA_ENDPOINT + `/competitions/${competitionId}/standings`;
  fetch(endpoint, {
    method: 'GET',
  })
  .then(res => res.json())
  .then(res => {
    const payload = res.standings.sort((a,b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    if (payload) {
      dispatch(getStandingSuccess(payload));
    }
  })
  .catch((e) => {
  });
};

export default standingSlice.reducer;
