import { createSlice } from '@reduxjs/toolkit';

const initialState= {
}


export const nextMatchSlice = createSlice({
  name: 'nextMatch',
  initialState,
  reducers: {
    getNextMatchSuccess: (state, action) => {
      return action.payload;
    },
  },
});

export const { getNextMatchSuccess } = nextMatchSlice.actions;

export const fetchNextMatch = (competitionId, team) => dispatch => {

  const endpoint = window._env_.REACT_APP_OPTA_ENDPOINT + `/competitions/${competitionId}/upcoming_match/${team}`;
  fetch(endpoint, {
    method: 'GET',
  })
  .then(res => res.json())
  .then(res => {
    dispatch(getNextMatchSuccess(res));
  })
  .catch((e) => {
  });
};

export default nextMatchSlice.reducer;
