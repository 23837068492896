import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';


import liveScoreReducer from './liveScoreSlice';
import standingReducer from './standingSlice';
import scheduleReducer from './scheduleSlice';
import nextMatchReducer from './nextMatchSlice';
import knockoutReducer from './knockoutSlice';

const persistConfig = {
  key: 'opta',
  storage: storage,
  // whitelist: [''],
  // blacklist: [''],
};

const persistReducers = persistReducer(persistConfig, combineReducers({
  liveScore: liveScoreReducer,
  standing: standingReducer,
  schedule: scheduleReducer,
  nextMatch: nextMatchReducer,
  knockout: knockoutReducer
}));
const middleware = [...getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
})];
const store = configureStore({
  reducer: persistReducers,
  middleware,
});

export const persistor = persistStore(store);

export default store;

