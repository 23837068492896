import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import store, { persistor } from "./store";
import Routes from "./Routes";

import './App.scss';

function App() {
  return (
      <div className="App">
        <Provider store={store}>
          <PersistGate loading={<div>Loading</div>} persistor={persistor}>
            <Routes />
          </PersistGate>
        </Provider>
      </div>
  );
}

export default App;
