const { screen: { width, height } } = window;
export const SCREEN_TYPE_CINEMA = 'cs_cinema';
export const SCREEN_TYPE_DIGITAL_ABRI = 'cs_digital_abri';
export const SCREEN_TYPE_DIGITAL_LANDSCAPE = 'cs_digital_landscape';
export const SCREEN_TYPE_KATHEDRAAL = 'cs_kathedraal';
export const SCREEN_TYPE_ROKIN_ROLLS = 'cs_rokin_rolls';
export const SCREEN_TYPE_THREESIXTY = 'cs_threesixty';
export const SCREEN_TYPE_TRIPLE = 'cs_triple';
const screenTypes = [
  { name: SCREEN_TYPE_CINEMA, width: 1344, height: 768, orientation: 'landscape' },
  { name: SCREEN_TYPE_DIGITAL_ABRI, width: 1080, height: 1920, orientation: 'portrait' },
  { name: SCREEN_TYPE_DIGITAL_LANDSCAPE, width: 1920, height: 1080, orientation: 'landscape' },
  { name: SCREEN_TYPE_KATHEDRAAL, width: 640, height: 1152, orientation: 'portrait' },
  { name: SCREEN_TYPE_ROKIN_ROLLS, width: 1152, height: 640, orientation: 'landscape' },
  { name: SCREEN_TYPE_THREESIXTY, width: 1792, height: 960, orientation: 'landscape' },
  { name: SCREEN_TYPE_TRIPLE, width: 3240, height: 1920, orientation: 'landscape' },
];

const getScreenType = (name) => {
  let screenType;
  if (name) {
    screenType = screenTypes.find(st => st.name === name);
  } else {
    screenType = screenTypes.find(st => st.width === width && st.height === height);
  }
  if (!screenType) {
    if (width < height) {
      screenType = screenTypes.find(st => st.name === SCREEN_TYPE_DIGITAL_ABRI);
    } else {
      screenType = screenTypes.find(st => st.name === SCREEN_TYPE_DIGITAL_LANDSCAPE);
    }
  }
  return screenType;
}
 export default getScreenType;
